const Page = {
  title: "Gambling in Singapore",
  description:
    "Due to Singapore's strict rules regarding gambling, every player residing in Singapore should be aware that online gambling is strictly illegal. With such strict rules, players will tend to find ways to access online gambling sites such as the usage of VPN. Although it is impossible to completely block out Singaporean players from wanting to access online gambling sites, there are still online gambling websites that will offer their services to players residing in Singapore. Although, those players agreeing to the terms of the site will have to take inconsideration that they will bear the risk and any decisions made solely by them will be at their own risk as the website will not be held responsible.",
  subtopic: "History",
  description_2:
    "Gambling has been rather popular in Singapore for many years. Before 2014 when the government changed the laws, it's accepted and accessible although now due to the laws, the online gambling operators have limit their acceptance of players living in Singapore. Although some websites still offer their services, it does not change the fact that online gambling is still illegal in the eyes of the Singaporean government. It’s important to realise that you are playing at your own risk, but that each is well-regulated and proven to be fair to players.",
  review_1:
    "The best online Gambling Operator for players residing in Singapore.",
  review_2:
    "Below are the list of online gambling operators that we think are great for players residing in Singapore.",
  poker_review: "Poker Review",
  casino_review: "Casino Review",
}

export default Page
