import Natural8Banner from "../images/poker/natural8-banner.png"
import Natural8Logo from "../images/poker/n8-logo.png"

import RedDragonBanner from "../images/poker/red-dragon-banner.jpg"
import RedDragonLogo from "../images/poker/red-dragon-logo.png"

import FirePokerBanner from "../images/poker/firepoker-banner.jpg"
import FirePokerLogo from "../images/poker/firepoker-logo.png"

import PPBanner from "../images/poker/pppoker-banner.jpg"
import PPLogo from "../images/poker/pppoker-logo.png"

import UPokerBanner from "../images/poker/upoker-banner.png"
import UPokerLogo from "../images/poker/upoker-logo.png"

import GGPokerBanner from "../images/poker/gg-banner.jpg"
import GGPokerLogo from "../images/poker/gg-logo.jpg"

import PokerokBanner from "../images/poker/pokerok-banner.jpg"
import PokerokLogo from "../images/poker/pokerok-logo.jpg"

const PokerReview = [
  {
    name: "Natural8",
    banner: Natural8Banner,
    logo: Natural8Logo,
    description:
      "One of the best online gambling websites we've came across is www.natural8.com where they offer a variety of deposit and withdrawal options for players such as Neteller, Skrill, Ecopayz and Cryptocurrencies. They offer an excellent games with many tournaments running every hour. The playing experience has been positive and they offer really great promotions monthly.",
    offers: [
      "All sorts of monthly promotions catered to both small and big time players",
      "Free tickets to exclusive tournaments",
      "Lobby games where players can select whichever tables they want to play",
      "Excellent 24/7 customer support",
      "Secure, trustworthy and reliable games",
      "Many safe deposit and withdrawal payment options",
      "Fast deposit and withdrawal times",
      "Tournaments every hour",
      "Games suitable for both big and small time players",
      "Games where you can play via desktop and mobile",
      "Great website with clear information on promotions, games etc",
      "Offers Smart HUD where players can quickly learn your opponent's tendencies on the spot, and use them to your strategic advantage.",
      "Detailed information about all their current daily, weekly, monthly promotions, with guides and tutorials on how to play for first time players who are looking to start playing",
    ],
    note:
      "Do note that Singaporean players can still play on Natural8.com as it is carefully regulated, but not by the Singapore government.",
    link: "https://www.natural8.com/",
    rating: "4.5",
  },
  {
    name: "Red Dragon Poker",
    banner: RedDragonBanner,
    logo: RedDragonLogo,
    description:
      "Red Dragon Poker is one of the latest Chinese online gaming operators that has ventured out overseas. It mostly offers soft action at low and medium stakes. It's not widely popular in the market yet but majority of the players are Chinese and they also have players from Singapore.",
    offers: [
      "players looking for soft action at low and mid-stakes",
      "tables in Chinese Renminbi (CNY) currency",
      "Artificial customer support",
      "Offers Skrill, Neteller, ecoPayz, and cryptocurrencies like BTC",
      "Pretty simple website with little to no information",
    ],
    note: "",
    link: "https://www.46mp.com",
    rating: "2.0",
  },
  {
    name: "Fire Poker Pro",
    banner: FirePokerBanner,
    logo: FirePokerLogo,
    description:
      "Fire Poker Pro is the latest online poker operator in the market. When it started, they cater mostly to the chinese market, similar to Red Dragon Poker but they have since ventured out into the market. It uses the same structure introduced by other Asian applications such as Red Dragon Poker and their currency is Chinese Renminbi (CNY). Fire Poker Pro is still unpopular in the western world and No Limit Hold'em is the preferred game but there are also some actions for Omaha and HU.",
    offers: [
      "strong Hold'em games",
      "Direct cashier and only one main club instead of several ones",
      "tables in Chinese Renminbi (CNY) currency",
      "Offers Skrill, Neteller, ecoPayz, and cryptocurrencies like BTC",
      "Pretty simple website with little to no information",
    ],
    note: "",
    link: "http://www.firepokerpro.com",
    rating: "2.0",
  },
  {
    name: "PPPoker",
    banner: PPBanner,
    logo: PPLogo,
    description:
      "PPPoker is another mobile app poker platform that has been growing in the online poker industry. Being only a poker software, not an operator itself, in order to play in the poker room, players can only make deposits and withdrawals via agents. PPPoker allows players to download and play using iOS, Android and PC. The market PPPoker targets are mainly amateur players from Asia. Traffic is growing fast on PPPoker this days attracting lots of new players from Thailand, Macau and Singapore. Real money games on PPPoker are hosted on private clubs. Multi-tabling is limited and all games are private. In order to play, players will need a trusted agent and only a few clubs will offer tournaments.",

    offers: [
      "Skrill and Neteller deposits and withdrawals",
      "Several club based applications where players need to join/be assigned to a club to play",
      "Limited promotions",
      "Pretty simple website with little to no information",
      "Customisable such as car deck and bet sizing",
    ],
    note: "",
    link: "https://pppoker.net",
    rating: "3.5",
  },
  {
    name: "Upoker",
    banner: UPokerBanner,
    logo: UPokerLogo,
    description:
      "Upoker belongs to a new generation of poker applications  that are currently in the market. The software evolution have led to quick development of Upoker as a whole. The appearance of the tables at Upoker is very similar to PPPoker, the players have a wide range of customization options including the card deck and bet sizing. The tracking software is not available but players can check basic information about the opponents.",
    offers: [
      "ecoPayz, Neteller, Skrill and Bitcoin deposit and withdrawal options",
      "Mobile app downloads only",
      "Club based applications where players need to join/be assigned to a club to play",
      "Limited promotions",
      "Pretty simple website with little to no information",
      "Customisable such as car deck and bet sizing",
    ],
    note: "",
    link: "http://upoker.net",
    rating: "3.5",
  },
  {
    name: "GGPoker",
    banner: GGPokerBanner,
    logo: GGPokerLogo,
    description:
      "GGNetwork is the largest poker network in Asia and has been offering their softwares to a number of brands. They've since started GGPoker, a common name with the network. Since 2017, ggpoker has started their own poker brand mainly to target the European poker market. It is a place for convenient enjoyable games for poker fans. The network offers decent promotions on their website, favouring both big and small time players. ",
    offers: [
      "Lobby games where players can select whichever tables they want to play",
      "Secure, trustworthy and reliable games",
      "Limited deposit and withdrawal options",
      "Tournaments every hour",
      "Games where you can play via desktop and mobile",
      "Great website with clear information on promotions, games, FAQs",
    ],
    note: "",
    link: "https://en.ggpoker.com/",
    rating: "4.0",
  },
  {
    name: "PokerOK",
    banner: PokerokBanner,
    logo: PokerokLogo,
    description:
      "PokerOK is considered new in the online poker world. The company mainly focuses on targeting players from Russia and post-Soviet countries and supports their local payment methods. They mostly focus on attracting recreational players and the games are pretty soft. The promotions are standard, most of them are also being offered in other poker rooms such as Natural8.",
    offers: [
      "Soft games for their targeted audience which are recreational players",
      "Deposit and withdrawal options limited to their own local methods",
      "Tournaments every hour",
      "Games where you can play via desktop and mobile",
      "Great website with clear information on promotions, games etc but mostly in Russian",
    ],
    note: "",
    link: "https://gg.pokerok.com/",
    rating: "4.0",
  },
]

export default PokerReview
